import { Grid } from '@material-ui/core'
import styles from './CentralCardLayout.module.scss'
import { CentralCardLayoutProps } from './CentralCardLayout.types'
import { Alert, Logo, Spacer } from '@percent/lemonade'
import { ReactComponent as PlusIcon } from '../../../../../shared/ui-lemonade/src/assets/images/plus-icon.svg'
import HeartImage from '../../../../../shared/ui-lemonade/src/assets/images/heart.png'
import SmileImage from '../../../../../shared/ui-lemonade/src/assets/images/smile.png'
import classNames from 'classnames/bind'

const cx = classNames.bind(styles)

export const CentralCardLayout = ({
  heading = '',
  children,
  handleSubmit,
  AgentVerificationLogo,
  agentVerificationLink,
  backgroundImages = false,
  CustomHeaderImage,
  footer,
  alignCenter,
  error,
}: CentralCardLayoutProps) => (
  <Grid
    spacing={0}
    container
    direction="column"
    alignItems="center"
    justifyContent="center"
    className={styles.pageContainer}
  >
    <Logo className={styles.goodstackLogo} />
    <Grid
      item
      xs={12}
      className={cx({ formAndImagesContainer: true, autoTopMargin: !!footer })}
    >
      <div className={styles.formContainer}>
        {CustomHeaderImage && (
          <div className={styles.headerImageWrapper}>{CustomHeaderImage}</div>
        )}
        <form onSubmit={handleSubmit} className={styles.form}>
          {AgentVerificationLogo && (
            <>
              <div className={styles.logosWrapper}>
                <a
                  href={agentVerificationLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    alt="cause-logo"
                    src={AgentVerificationLogo}
                    className={styles.causeLogo}
                  />
                </a>
                <Spacer axis="horizontal" size={6} />
                <PlusIcon />
                <Spacer axis="horizontal" size={6} />
                <Logo className={styles.goodstackLogoSmall} />
              </div>
              <Spacer axis="vertical" size={6} />
            </>
          )}
          {heading && (
            <h5
              className={cx({ headingText: true, alignCenter })}
              data-testid="auth-view-heading"
            >
              {heading}
            </h5>
          )}
          {error && (
            <>
              <Alert variant="error" title={error} />
              <Spacer axis="vertical" size={6} />
            </>
          )}
          {children}
        </form>
      </div>
      {backgroundImages && (
        <img src={SmileImage} alt="smile" className={styles.smileImage} />
      )}
      {backgroundImages && (
        <img src={HeartImage} alt="heart" className={styles.heartImage} />
      )}
    </Grid>
    {footer && <div className={styles.footerWrapper}>{footer}</div>}
  </Grid>
)
